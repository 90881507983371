.menu_root{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    position: fixed;
    left: 0px;
    top: 0px;
    transition: opacity 300ms linear 0ms;
    opacity: 0;
    pointer-events: none;
    display: flex;
}

.menu_container{
    height: 100%;
    min-width: 232px;
    width: 332px;
    background-color: rgb(6,6,6);
    transition: margin-left 300ms linear 0ms;
    margin-left: -400px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.phpne_menu_close {
    width: 40px;
    height: 40px;
    margin-left: 10px;
    margin-top: 5px;
    float: left;
}

.phone_menu_title {
    width: 234px;
    height: 24px;
    margin-left: 20px;
    margin-top: 33px;
    margin-bottom: 14px;
    flex-shrink:0;
}

.item_menu_text_s{
    font-family: 'TomorrowMedium';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
    color: #EAEAEA;
    margin-left: 8px;
    flex-shrink:0;
    white-space: pre-wrap;
}

.item_menu_text_n{
    font-family: 'TomorrowMedium';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
    color: #868686;
    margin-left: 8px;
}

.share_icon {
    width: 283px;
    height: 50px;
}

.item_egg_text {

    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height */
    color: #02D4CB;
    margin-left: 10px;
    opacity: 0.5;

}

.item_egg_text_en {

    font-family: 'Tomorrow';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    color: #02D4CB;
    margin-left: 10px;
    opacity: 0.5;

}
