@font-face {
  font-family: 'Tomorrow';
  font-style: normal;
  src: url('https://cijian-link-app.oss-cn-zhangjiakou.aliyuncs.com/fonts/TomorrowSemiBold.ttf');
  /* src: url(data-base64:~font/TomorrowSemiBold.ttf); */
}

@font-face {
  font-family: 'TomorrowRegular';
  font-style: normal;
  font-weight: 400;
  src: url('https://cijian-link-app.oss-cn-zhangjiakou.aliyuncs.com/fonts/Tomorrow-Regular.ttf');
  /* src: url(data-base64:~font/TomorrowSemiBold.ttf); */
}

@font-face {
  font-family: 'TomorrowMedium';
  font-style: normal;
  font-weight: 400;
  src: url('https://cijian-link-app.oss-cn-zhangjiakou.aliyuncs.com/fonts/Tomorrow-Medium.ttf');
  /* src: url(data-base64:~font/TomorrowSemiBold.ttf); */
}

@font-face {
  font-family: 'Source Han Serif CN';
  font-style: normal;
  src: url('https://cijian-link-app.oss-cn-zhangjiakou.aliyuncs.com/fonts/SourceHanSerifCN-Bold-2.otf');
  /* src: url(data-base64:~font/TomorrowSemiBold.ttf); */
}

@font-face {
  font-family: 'Latin Modern Math';
  font-style: normal;
  src: url('https://cijian-link-app.oss-cn-zhangjiakou.aliyuncs.com/fonts/latinmodern-math.otf');
  /* src: url(data-base64:~font/TomorrowSemiBold.ttf); */
}


@font-face {
  font-family: 'FZYouSongS';
  font-style: normal;
  src: url('https://cijian-link-app.oss-cn-zhangjiakou.aliyuncs.com/fonts/FZYouSJW_509R.TTF');
}

@font-face {
  font-family: 'Sthginkra';
  font-style: normal;
  src: url('https://cijian-link-app.oss-cn-zhangjiakou.aliyuncs.com/fonts/ZhouFangRiMingTiXieTi-2.otf');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  src: url('https://cijian-link-app.oss-cn-zhangjiakou.aliyuncs.com/fonts/Montserrat-VariableFont_wght.subset.ttf');
}


.App {
  text-align: center;
  background-color: #474747;
  height: 100%;
}