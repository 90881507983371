

.block {
    background: rgba(0, 0, 0, 0.58);
    border: 2px solid #242424;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 60px;
    line-height: 73px;
    display: flex;
    align-items: center;
    text-align: center;

    /* Manifesto/manifesto-white */

    color: #EAEAEA;

    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.59);
}
.phone_block {
    background: rgba(0, 0, 0, 0.58);
    border: 0.5px solid #242424;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 27px;
    line-height: 33px;
    display: flex;
    align-items: center;
    text-align: center;

    /* Manifesto/manifesto-white */

    color: #EAEAEA;

    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.59);
}


.label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    
    /* Manifesto/manifesto-darkgrey */
    
    color: #868686;
}

.phone_label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 7px;
    line-height: 9px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    
    /* Manifesto/manifesto-darkgrey */
    
    color: #868686;
    /* transform: scale(0.8); */
}



.sep {
    margin-top: 25px;
    width: 40px;
    height: 50px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 49px;
    /* identical to box height */
    
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    
    /* Manifesto/manifesto-white */
    
    color: #EAEAEA;
    
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.59);
}

.phone_sep {
    margin-top: 13px;
    width: 18px;
    height: 22px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    
    /* Manifesto/manifesto-white */
    
    color: #EAEAEA;
    
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.59);
}

.label2{
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    text-align: left;
    line-height: 32px;
    color: #EAEAEA;
}